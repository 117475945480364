<template>
	<div>
		<h2 class="typo-h4 position-relative">
			{{ title }}
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				@click="handleAddConditionClick"
			>
				Add condition
			</CButton>
		</h2>
		<p class="typo-caption color-black-45">
			{{ description }}
		</p>
		<div class="mt-3">
			<BaseNoItemBanner v-if="!conditionList || conditionList.length <= 0" text="There is no condition added." />
			<div v-else :class="{ 'pt-3 px-3 rounded-sm bg-gray-100': !noConditionMatch }">
				<CRow v-if="!noConditionMatch">
					<CCol md="8">
						<label>Condition</label>
					</CCol>
					<CCol md="4" class="text-right">
						<CInputRadioGroup
							:options="CONDITION_TYPE_OPTIONS"
							:checked="conditionType"
							custom
							inline
							size="sm"
							class="radio-group"
							name="product-condition-type"
							@update:checked="handleConditionTypeChange"
						/>
					</CCol>
				</CRow>

				<CRow>
					<CCol lg="12">
						<ul class="list-unstyled p-0 mt-3">
							<ProductConditionList
								v-for="(condition, index) in conditionList"
								:key="`condition${index}`"
								:default-data="condition"
								:is-rule-type-all="isRuleTypeAll"
								:no-condition-match="noConditionMatch"
								is-show-edit-button
								@onEdit="handleEditCondition(index)"
							/>
						</ul>
					</CCol>
				</CRow>
			</div>
		</div>

		<ModalProductCondition
			ref="modal-product-condition"
			:is-edit="selectedCondition != null"
			:default-data="selectedCondition"
			:is-rule-type-all="isRuleTypeAll"
			:no-condition-match="noConditionMatch"
			:no-subkey="noSubkey"
			is-multiple-condition
			@onRemove="handleRemoveCondition"
			@onConfirm="handleChangeCondition"
		/>
	</div>
</template>

<script>
import ModalProductCondition from '@/components/ModalProductCondition.vue';
import ProductConditionList from '@/components/ProductConditionList.vue';
import { CONDITION_TYPES, CONDITION_TYPE_OPTIONS } from '../enums/promotions';
import { transformedItemConditions, transformedFlatProductCondition } from '../assets/js/transform/promotions';

export default {
	name: 'ProductCondition',

	components: {
		ModalProductCondition,
		ProductConditionList,
	},

	props: {
		title: {
			type: String,
			default: 'Product condition',
		},
		description: {
			type: String,
			default: 'No product condition adding will apply to all products on storefront.',
		},
		defaultData: {
			type: Object,
			default: null,
		},
		isRuleTypeAll: {
			type: Boolean,
			default: false,
		},
		noConditionMatch: {
			type: Boolean,
			default: false,
		},
		noSubkey: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { type = CONDITION_TYPES.ALL, param = {} } = this.defaultData || {};
		const conditionList = (param.conditions || []).map(transformedFlatProductCondition);

		return {
			CONDITION_TYPE_OPTIONS,
			selectedIndex: -1,
			conditionType: type,
			conditionList,
		};
	},

	computed: {
		selectedCondition() {
			if (this.selectedIndex && this.selectedIndex < 0) { return null; }

			return (this.conditionList[this.selectedIndex] || null);
		},
	},

	methods: {
		async openModalProductCondition() {
			// wait for renderring new selected list, props of modal
			await this.$nextTick();
			this.$refs['modal-product-condition'].open();
		},
		handleAddConditionClick() {
			this.selectedIndex = -1;
			this.openModalProductCondition();
		},
		handleConditionTypeChange(value) {
			this.conditionType = value;
			this.$emit(
				'onUpdate',
				transformedItemConditions(value, this.conditionList),
			);
		},
		handleRemoveCondition() {
			if (this.selectedIndex === -1) { return; }

			this.conditionList.splice(this.selectedIndex, 1);
			this.$emit(
				'onUpdate',
				transformedItemConditions(this.conditionType, this.conditionList),
			);
		},
		handleChangeCondition(condition = {}) {
			if (this.selectedIndex >= 0) {
				// Update condition list
				this.$set(this.conditionList, this.selectedIndex, condition);
			} else {
				this.conditionList.push(condition);
			}

			this.$emit(
				'onUpdate',
				transformedItemConditions(this.conditionType, this.conditionList),
			);
		},
		handleEditCondition(index) {
			this.selectedIndex = index;
			this.openModalProductCondition();
		},
	},
};
</script>
